const HOME_BREADCRUMB = [{ text: 'Startseite', to: { name: 'home' } }]

export default [
    {
        path: '/domains',
        name: 'domains',
        component: () => import(/* webpackChunkName: "domains" */ '../../views/public/product/Domains.vue'),
        meta: {
            breadcrumb: [
                ...HOME_BREADCRUMB,
                { text: 'Domain bestellen' }
            ]
        }
    },
    {
        path: '/webspace',
        name: 'webspace',
        component: () => import(/* webpackChunkName: "webspace" */ '../../views/public/product/Webspace.vue'),
        meta: {
            breadcrumb: [
                ...HOME_BREADCRUMB,
                { text: 'Webspace bestellen' }
            ]
        }
    },
    {
        path: '/rootserver',
        name: 'rootserver',
        component: () => import(/* webpackChunkName: "rootserver" */ '../../views/public/product/RootServer.vue'),
        meta: {
            breadcrumb: [
                ...HOME_BREADCRUMB,
                { text: 'Rootserver bestellen' }
            ]
        }
    },
    {
        path: '/minecraftserver',
        name: 'minecraftserver',
        component: () => import(/* webpackChunkName: "minecraftserver" */ '../../views/public/product/MinecraftServer.vue'),
        meta: {
            breadcrumb: [
                ...HOME_BREADCRUMB,
                { text: 'Minecraft Server bestellen' }
            ]
        }
    },
    {
        path: '/teamspeak-server',
        name: 'teamspeak-server',
        component: () => import(/* webpackChunkName: "teamspeak-server" */ '../../views/public/product/TeamspeakServer.vue'),
        meta: {
            breadcrumb: [
                ...HOME_BREADCRUMB,
                { text: 'Teamspeak Server bestellen' }
            ]
        }
    },
    {
        path: '/dedicated-server',
        name: 'dedicated-server',
        component: () => import(/* webpackChunkName: "dedicated-server" */ '../../views/public/product/DedicatedServer.vue'),
        meta: {
            breadcrumb: [
                ...HOME_BREADCRUMB,
                { text: 'Dedicated Server bestellen' }
            ]
        }
    },
    {
        path: '/nextcloud',
        name: 'nextcloud',
        component: () => import(/* webpackChunkName: "nextcloud" */ '../../views/public/product/Nextcloud.vue'),
        meta: {
            breadcrumb: [
                ...HOME_BREADCRUMB,
                { text: 'Nextcloud bestellen' }
            ]
        }
    }
]