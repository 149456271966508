<template>
    <v-dialog max-width="600px" v-model="open">
        <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" v-bind="attrs" v-on="on" text>Anmelden</v-btn>
        </template>
        <v-card>
            <v-form @submit.prevent="login()">
                <v-card-title>
                    <span class="headline">Anmelden</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-text-field v-model="email" label="E-Mail" />
                        <v-text-field v-model="password" type="password" label="Passwort" />
                        <v-select v-if="twoFactorProvider != null" v-model="twoFactorProvider" label="2-Faktor Provider" :items="Object.keys(providers).map(p => ({ value: p, text: providers[p] }))" />
                        <v-text-field v-if="twoFactorProvider != null" v-model="twoFactorCode" label="2-Faktor Code" />
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="open = false">Abbrechen</v-btn>
                    <v-btn @click="login()" color="primary" type="submit">Anmelden</v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
import xeonserv from '../../apis/xeonserv'

export default {
    data() {
        return {
            open: false,
            providers: {
                TOTP: 'TOTP (Google Authenticator, Authy, ...)',
                BACKUP: 'Backup Code'
            },
            email: '',
            password: '',
            twoFactorProvider: null,
            twoFactorCode: ''
        }
    },
    created() {
        this.bus.$on('login:show', () => this.open = true)
    },
    methods: {
        login() {
            xeonserv().post('/auth/login', {
                email: this.email,
                password: this.password,
                two_factor_provider: this.twoFactorProvider,
                two_factor_code: this.twoFactorCode
            }).then(res => {
                localStorage.setItem('token', res.data.data.token)
                this.$store.dispatch('loadCurrentUser')
                this.open = false
                this.email = ''
                this.password = ''
            }, err => {
                if(err.response.status == 403)
                    this.twoFactorProvider = 'TOTP'
            })
        }
    }
}
</script>