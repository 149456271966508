const HOME_BREADCRUMB = [{ text: 'Startseite', to: { name: 'home' } }]

export default [
    {
        path: '/',
        name: 'home',
        component: () => import(/* webpackChunkName: "home" */ '../../views/public/Home.vue'),
        meta: {
          breadcrumb: [{ ...HOME_BREADCRUMB[0], to: undefined }]
        }
    },
    {
        path: '/technik',
        name: 'technik',
        component: () => import(/* webpackChunkName: "technik" */ '../../views/public/info/Technik.vue'),
        meta: {
            breadcrumb: [
                ...HOME_BREADCRUMB,
                { text: 'Unsere Technik' }
            ]
        }
    },
    {
        path: '/partner',
        name: 'partner',
        component: () => import(/* webpackChunkName: "partner" */ '../../views/public/info/Partner.vue'),
        meta: {
            breadcrumb: [
                ...HOME_BREADCRUMB,
                { text: 'Unsere Partner' }
            ]
        }
    },
    {
        path: '/faq',
        name: 'faq',
        component: () => import(/* webpackChunkName: "faq" */ '../../views/public/info/FAQ.vue'),
        meta: {
            breadcrumb: [
                ...HOME_BREADCRUMB,
                { text: 'Häufig gestellte Fragen' }
            ]
        }
    },
    {
        path: '/kontakt',
        name: 'kontakt',
        component: () => import(/* webpackChunkName: "kontakt" */ '../../views/public/info/Kontakt.vue'),
        meta: {
            breadcrumb: [
                ...HOME_BREADCRUMB,
                { text: 'Kontakt' }
            ]
        }
    }
]