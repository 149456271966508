<template>
    <div>
        <div style="margin-bottom: 1px; height: 40px;">
            <v-text-field
            style="float: right; width: 300px; margin-left: 10px;"
            v-if="searchable"
            v-model="searchValue"
            append-icon="mdi-magnify"
            :class="{ active: searchValue.length > 0 }"
            hide-details
            outlined
            dense
            />
            <div style="float: right;">
                <slot name="actions" />
            </div>
        </div>
        <v-data-table
            :items="items"
            :options.sync="options"
            :server-items-length="total"
            :loading="loading"
            v-bind="$attrs"
        >
            <template v-for="(index, name) in $slots" v-slot:[name]>
                <slot :name="name" />
            </template>
            <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
                <slot :name="name" v-bind="data"></slot>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import xeonserv from '../apis/xeonserv'

export default {
    name: 'datatable',
    data() {
        return {
            loading: true,
            items: [],
            options: {},
            searchValue: '',
            total: 0,
            searchTimer: undefined,
            searchChanged: false
        }
    },
    props: {
        path: {
            type: String
        },
        searchable: {
            type: Boolean,
            default: false
        },
        nopagination: {
            type: Boolean,
            default: false
        },
        extra: {
            type: Boolean
        }
    },
    created() {
        this.searchTimer = setInterval(() => {
            if(this.searchChanged) {
                this.searchChanged = false
                if(!this.nopagination)
                    this.loadData()
            }
        }, 1000)
        if(this.nopagination)
            this.loadData()
        this.$on('reloaddata', this.loadData)
    },
    beforeDestroy() {
        clearInterval(this.searchTimer)
    },
    watch: {
        options() {
            if(!this.nopagination)
                this.loadData()
        },
        searchValue() {
            this.searchChanged = true
        }
    },
    methods: {
        loadData() {
            const params = new URLSearchParams()
            if(!this.nopagination) {
                params.append('page', this.options.page)
                params.append('page_size', this.options.itemsPerPage)
            }
            if(this.searchValue.length > 0)
                params.append('search', this.searchValue)
            xeonserv().get(this.path + '?' + params.toString()).then(res => {
                this.items = res.data.data
                this.total = this.nopagination ? res.data.length : res.data.pagination.total
                this.loading = false
                this.$emit('data', this.items)
            })
        }
    }
}
</script>