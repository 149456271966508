const HOME_BREADCRUMB = [{ text: 'Startseite', to: { name: 'home' } }]

export default [
    {
        path: '/impressum',
        name: 'impressum',
        component: () => import(/* webpackChunkName: "impressum" */ '../../views/public/legal/Impressum.vue'),
        meta: {
            breadcrumb: [
                ...HOME_BREADCRUMB,
                { text: 'Impressum' }
            ]
        }
    },
    {
        path: '/agb',
        name: 'agb',
        component: () => import(/* webpackChunkName: "agb" */ '../../views/public/legal/AGB.vue'),
        meta: {
            breadcrumb: [
                ...HOME_BREADCRUMB,
                { text: 'AGB' }
            ]
        }
    },
    {
        path: '/datenschutz',
        name: 'datenschutz',
        component: () => import(/* webpackChunkName: "datenschutz" */ '../../views/public/legal/Datenschutz.vue'),
        meta: {
            breadcrumb: [
                ...HOME_BREADCRUMB,
                { text: 'Datenschutzerklärung' }
            ]
        }
    }
]