import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../views/layout/Layout'
import RouterView from '../views/layout/RouterView'
import publicRoutes from './public'
import dashboardRoutes from './dashboard'
import adminRoutes from './admin'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Layout,
    children: [
      ...publicRoutes,
      {
        path: '/dashboard',
        component: RouterView,
        children: dashboardRoutes
      },
      {
        path: '/admin',
        component: RouterView,
        children: adminRoutes
      }
    ]
  },
  {
    path: '/rechargestripe/:id',
    name: 'rechargestripe',
    component: () => import(/* webpackChunkName: "rechargestripe" */ '../views/helper/RechargeStripe.vue')
  },
  {
    path: '/return/:provider',
    name: 'return',
    component: () => import(/* webpackChunkName: "return" */ '../views/helper/Return.vue')
  },
  {
    path: '*',
    component: Layout,
    children: [
      {
        path: '/',
        name: '404',
        component: () => import(/* webpackChunkName: "404" */ '../views/NotFound.vue'),
        meta: {
          breadcrumb: [
            {
              text: 'Seite nicht gefunden'
            }
          ]
        }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
