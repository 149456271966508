const ADMIN_BREADCRUMB = [{ text: 'Admin', to: { name: 'admin' } }]

export default [
    {
        path: '/admin',
        name: 'admin',
        component: () => import(/* webpackChunkName: "admin" */ '../../views/admin/AdminDashboard'),
        meta: {
            breadcrumb: [
                ...ADMIN_BREADCRUMB,
                { text: 'Dashboard' }
            ]
        }
    },
    {
        path: '/admin/users',
        name: 'admin-users',
        component: () => import(/* webpackChunkName: "admin-users" */ '../../views/admin/users/UserList'),
        meta: {
            breadcrumb: [
                ...ADMIN_BREADCRUMB,
                { text: 'Benutzer' }
            ]
        }
    },
    {
        path: '/admin/addresses',
        name: 'admin-addresses',
        component: () => import(/* webpackChunkName: "admin-users" */ '../../views/admin/addresses/AddressList'),
        meta: {
            breadcrumb: [
                ...ADMIN_BREADCRUMB,
                { text: 'Adressen' }
            ]
        }
    },
    {
        path: '/admin/testimonials',
        name: 'admin-testimonials',
        component: () => import(/* webpackChunkName: "admin-testimonials" */ '../../views/admin/TestimonialList'),
        meta: {
            breadcrumb: [
                ...ADMIN_BREADCRUMB,
                { text: 'Bewertungen' }
            ]
        }
    },
]