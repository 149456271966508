<template>
    <v-app dark>
        <v-app-bar
        app
        color="white"
        permanent
        >
            <div class="d-flex align-center">
                <Logo class="shrink mr-2" width="200" height="60" style="cursor: pointer" @click="$router.push({ name: 'home' })" />
            </div>

            <v-spacer></v-spacer>

            <v-toolbar-items>
                <v-btn text to="/domains" class="primary--text">Domains</v-btn>
                <v-btn text to="/webspace" class="primary--text">Webspace</v-btn>
                <v-btn text to="/rootserver" class="primary--text">Rootserver</v-btn>
                <v-btn text to="/minecraftserver" class="primary--text">Minecraft Server</v-btn>
                <v-btn text to="/teamspeak-server" class="primary--text">Teamspeak 3</v-btn>
                <v-btn text to="/dedicated-server" class="primary--text">Dedicated Server</v-btn>
                <v-btn text to="/nextcloud" class="primary--text">Nextcloud</v-btn>
            </v-toolbar-items>

            <v-spacer></v-spacer>

            <v-btn text @click="$store.dispatch('impersonateUser', null)" v-if="$store.state.impersonatedUserId">Zurück zu Admin</v-btn>

            <Registration v-if="!$store.state.current_user" />
            <Login v-if="!$store.state.current_user" />
            <Recharge />
            
            <v-menu offset-y open-on-hover v-if="$store.state.current_user">
                <template v-slot:activator="{ on, attrs }">
                <v-btn text v-bind="attrs" v-on="on">
                    Guthaben: {{ ($store.state.current_user.balance || 0).toFixed(2).replace('.', ',') }} €
                </v-btn>
                </template>
                <v-list>
                    <v-list-item link @click="bus.$emit('recharge:show')">
                        <v-list-item-title>Guthaben aufladen</v-list-item-title>
                    </v-list-item>
                    <v-list-item link :to="{ name: 'dashboard-transactions' }">
                        <v-list-item-title>Transaktionen</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
            <v-menu offset-y open-on-hover v-if="$store.state.current_user">
                <template v-slot:activator="{ on, attrs }">
                <v-btn text v-bind="attrs" v-on="on">
                    <v-icon color="primary">mdi-account</v-icon>
                </v-btn>
                </template>
                <v-divider />
                <v-list>
                <v-list-item>
                    <v-list-item-title><b>{{ $store.state.current_user.first_name + ' ' + $store.state.current_user.last_name }}</b><v-list-item-subtitle>K{{ $store.state.current_user.id }}</v-list-item-subtitle></v-list-item-title>
                </v-list-item>
                <v-divider />
                <v-list-item link :to="{ name: 'dashboard-account' }" exact>
                    <v-list-item-title>Mein Konto</v-list-item-title>
                </v-list-item>
                <v-list-item link :to="{ name: 'dashboard' }" exact>
                    <v-list-item-title>Meine Dienste</v-list-item-title>
                </v-list-item>
                <v-list-item link :to="{ name: 'dashboard-support' }" exact>
                    <v-list-item-title>Support</v-list-item-title>
                </v-list-item>
                <v-list-item link :to="{ name: 'admin' }" v-if="$store.state.current_user.admin" exact>
                    <v-list-item-title>Admin</v-list-item-title>
                </v-list-item>
                <v-divider />
                <v-list-item link @click="logout()">
                    <v-list-item-title>Abmelden</v-list-item-title>
                </v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar>

        <v-subheader style="background-color: #0E0E0E; margin-top: 65px; border-top: 3px solid #384045; border-bottom: 3px solid #384045;" class="white--text px-page">
            <v-breadcrumbs :items="$route.meta.breadcrumb || []">
                <template v-slot:item="{ item }" class="white--text">
                <v-breadcrumbs-item :to="item.to" active-class="">
                    {{ item.text.toUpperCase() }}
                </v-breadcrumbs-item>
                </template>
                <template v-slot:divider>
                <v-breadcrumbs-divider class="white--text">/</v-breadcrumbs-divider>
                </template>
            </v-breadcrumbs>
        </v-subheader>

        <v-main class="pt-0">
            <router-view />
        </v-main>

        <v-footer padless>
            <v-card
            flat
            tile
            style="width: 100%; border-top: 3px solid #384045;"
            class="px-page"
            color="#0E0E0E"
            >
                <v-card-text class="text-center">
                    <v-btn class="mx-4" icon>
                        <v-icon size="24px" color="white">mdi-youtube</v-icon>
                    </v-btn>
                    <v-btn class="mx-4" icon>
                        <v-icon size="24px" color="white">mdi-instagram</v-icon>
                    </v-btn>
                    <v-btn class="mx-4" icon>
                        <v-icon size="24px" color="white">mdi-twitter</v-icon>
                    </v-btn>
                </v-card-text>

                <v-card-text class="white--text">
                    <v-row>
                        <v-col cols="12" sm="3">
                            <h4>Rechtliches:</h4>
                            <ul class="mt-5">
                                <li><router-link :to="{ name: 'impressum' }">Impressum</router-link></li>
                                <li><router-link :to="{ name: 'agb' }">AGB</router-link></li>
                                <li><router-link :to="{ name: 'datenschutz' }">Datenschutz</router-link></li>
                            </ul>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <h4>Infos:</h4>
                            <ul class="mt-5">
                                <li><router-link :to="{ name: 'technik' }">Unsere Technik</router-link></li>
                                <li><router-link :to="{ name: 'partner' }">Unsere Partner</router-link></li>
                                <li><router-link :to="{ name: 'faq' }">FAQ</router-link></li>
                                <li><router-link :to="{ name: 'kontakt' }">Kontakt</router-link></li>
                            </ul>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <a class="twitter-timeline"
                            href="https://twitter.com/XeonServ"
                            data-chrome="nofooter noborders"
                            data-height="250"
                            >
                            Tweets von @XeonServ
                            </a>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <h4>Zahlungsmethoden:</h4>
                            <v-img width="200" class="mt-5" src="../../assets/img/payment/psc.png" />
                            <v-img width="200" src="../../assets/img/payment/paypal.png" />
                            <StripeLogo width="200" height="100" viewBox="0 0 370 130" />
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-text class="white--text text-center">
                    Copyright © 2016 - {{ new Date().getFullYear() }} XeonServ. All rights reserved.<br>
                    Gemäß § 19 UStG wird keine Umsatzsteuer ausgewiesen.
                </v-card-text>
            </v-card>
        </v-footer>
        <div class="notification-view">
            <v-alert v-for="notification in notifications" :key="notification.id" :type="notification.type">{{ notification.message }}</v-alert>
        </div>
    </v-app>
</template>

<script>
import Registration from '../../components/auth/Registration'
import Login from '../../components/auth/Login'
import Recharge from '../../components/user/Recharge'
import Logo from '../../assets/img/logo.svg'
import StripeLogo from '../../assets/img/payment/stripe.svg'

export default {
    components: {
        Registration,
        Login,
        Recharge,
        Logo,
        StripeLogo
    },
    data() {
        return {
            notifications: []
        }
    },
    created() {
        window.Logo = Logo
        this.bus.$on('notification:show', notification => {
            notification.id = (Math.random() * 100000)
            if(this.notifications.length == 3)
                this.notifications.pop()
            this.notifications.unshift(notification)
            setTimeout(() => this.notifications = this.notifications.filter(n => n.id != notification.id), notification.duration || 5000)
        })
    },
    methods: {
        logout() {
            localStorage.removeItem('token')
            this.$store.commit('setCurrentUser', undefined)
        }
    }
}
</script>

<style scoped>
.notification-view {
    width: 450px;
    position: fixed;
    right: 10px;
    bottom: 10px;
}
</style>