import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './assets/scss/app.scss'
import './util/twitter'
import bus from './bus'
import datatable from './components/DataTable.vue'
import VueTimers from 'vue-timers'

Vue.use(VueTimers)

Vue.config.productionTip = false

Vue.component('datatable', datatable)

Vue.mixin({
  data() {
    return {
      bus
    }
  }
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

store.dispatch('loadCurrentUser')
store.dispatch('loadTestimonials')