<template>
    <v-dialog max-width="800px" v-model="open" :persistent="!!payment">
        <v-card>
            <v-card-title v-if="!payment">
                <span class="headline">Guthaben aufladen</span>
            </v-card-title>
            <v-card-text v-if="!payment">
                <v-container>
                    <v-row >
                        <v-col md="4">
                            <div :class="{ pm: true, active: provider == 'PAYSAFECARD' }" @click="provider = 'PAYSAFECARD'">
                                <img src="../../assets/img/payment/psc.png" />
                            </div>
                        </v-col>
                        <v-col md="4">
                            <div :class="{ pm: true, active: provider == 'PAYPAL' }" @click="provider = 'PAYPAL'">
                                <img src="../../assets/img/payment/paypal.png" />
                            </div>
                        </v-col>
                        <v-col md="4">
                            <div :class="{ pm: true, active: provider == 'STRIPE' }" @click="provider = 'STRIPE'">
                                <StripeLogo height="60" viewBox="0 0 370 130" />
                            </div>
                        </v-col>
                    </v-row>
                    <v-text-field
                            v-model="amount"
                            type="number"
                            class="input-center"
                            style="font-size: 25px; margin-top: 40px;"
                            lang="de_DE"
                            step="0.01"
                            prefix="€"
                            outlined
                            pattern="^\d+(\.|\,)\d{2}$"
                        >
                    </v-text-field>
                    <v-btn @click="recharge()" color="primary" block elevation="5">Aufladen</v-btn>
                </v-container>
            </v-card-text>
            <v-card-text v-if="payment" class="text-center">
                <br><br><br><br>
                <v-progress-circular :size="70" :width="4" :color="payment.state == 'FAILED' ? 'red' : (payment.state == 'DONE' ? 'green': 'primary')" :value="payment.state == 'PENDING' ? 0 : 100" :indeterminate="payment === true || payment.state == 'PENDING'">
                    <v-icon v-if="payment.state == 'DONE'" size="50" color="green">mdi-check</v-icon>
                    <v-icon v-if="payment.state == 'FAILED'" size="50" color="red">mdi-close</v-icon>
                </v-progress-circular><br><br>
                <h3 v-if="payment === true">Bereite Zahlung vor</h3>
                <h3 v-if="payment.state == 'PENDING'">Warte auf Zahlungseingang</h3>
                <h3 v-if="payment.state == 'DONE'">Zahlung abgeschlossen</h3>
                <h3 v-if="payment.state == 'FAILED'">Zahlung fehlgeschlagen</h3>
                <br><br><br>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import xeonserv from '../../apis/xeonserv'
import StripeLogo from '../../assets/img/payment/stripe.svg'

export default {
    components: { StripeLogo },
    data() {
        return {
            open: false,
            provider: 'PAYPAL',
            amount: 5,
            payment: null,
            checkTimer: undefined
        }
    },
    created() {
        this.bus.$on('recharge:show', (amount = 5) => {
            this.amount = amount
            this.open = true
        })
    },
    beforeDestroy() {
        clearInterval(this.checkTimer)
    },
    methods: {
        recharge() {
            this.payment = true
            xeonserv().post('/payments', {
                provider: this.provider,
                amount: this.amount
            }).then(res => {
                this.payment = res.data.data
                this.checkTimer = setInterval(this.check, 5000)
                switch(this.payment.provider) {
                    case 'PAYSAFECARD':
                    case 'PAYPAL':
                        window.open(this.payment.approvement.url, "Authorize", "width=500,height=600")
                        break
                    case 'STRIPE': {
                        window.open(window.location.origin + '/rechargestripe/' + this.payment.approvement.id, "Authorize", "width=500,height=600")
                        break
                    }
                }
            }, () => this.payment = null)
        },
        check() {
            xeonserv().get('/payments/' + this.payment.id).then(res => {
                if(this.checkTimer == null)
                    return
                this.payment = res.data.data
                if(this.payment.state !== 'PENDING') {
                    this.$store.dispatch('loadCurrentUser')
                    setTimeout(() => {
                        this.open = false
                        setTimeout(() => {
                            this.payment = false
                        }, 1000)
                    }, 3000)
                    clearInterval(this.checkTimer)
                }
            })
        }
    }
}
</script>

<style scoped>
.input-center >>> input {
    text-align: center;
}
</style>

<style lang="scss" scoped>
.pm {
    height: 120px;
    border: 3px dashed lightgray;
    padding-top: 30px;
    text-align: center;
    border-radius: 15px;
    cursor: pointer;
    user-select: none;
    &.active {
        border-style: solid;
        border-color: var(--primary)
    }
    img {
        display: inline-block;
        width: 80%;
        -webkit-user-drag: none;
    }
}
</style>