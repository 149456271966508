const DASHBOARD_BREADCRUMB = [{ text: 'Dashboard', to: { name: 'dashboard' } }]

export default [
    {
        path: '/dashboard/transactions',
        name: 'dashboard-transactions',
        component: () => import(/* webpackChunkName: "dashboard-transactions" */ '../../views/dashboard/TransactionList.vue'),
        meta: {
            breadcrumb: [
                ...DASHBOARD_BREADCRUMB,
                { text: 'Transaktionen' }
            ]
        }
    },
    {
        path: '/dashboard/orders',
        name: 'dashboard-orders',
        component: () => import(/* webpackChunkName: "dashboard-orders" */ '../../views/dashboard/OrderList.vue'),
        meta: {
            breadcrumb: [
                ...DASHBOARD_BREADCRUMB,
                { text: 'Bestellungen' }
            ]
        }
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ '../../views/dashboard/MyServices.vue'),
        meta: {
            breadcrumb: [
                ...DASHBOARD_BREADCRUMB,
                { text: 'Meine Dienste' }
            ]
        }
    },
    {
        path: '/dashboard/account',
        name: 'dashboard-account',
        component: () => import(/* webpackChunkName: "dashboard-account" */ '../../views/dashboard/account/Account.vue'),
        meta: {
            breadcrumb: [
                ...DASHBOARD_BREADCRUMB,
                { text: 'Mein Konto' }
            ]
        }
    },
    {
        path: '/dashboard/support',
        name: 'dashboard-support',
        component: () => import(/* webpackChunkName: "dashboard-support" */ '../../views/dashboard/TicketList.vue'),
        meta: {
            breadcrumb: [
                ...DASHBOARD_BREADCRUMB,
                { text: 'Support' }
            ]
        }
    },
    {
        path: '/dashboard/support/:id',
        name: 'dashboard-support-ticket',
        component: () => import(/* webpackChunkName: "dashboard-support-ticket" */ '../../views/dashboard/Ticket.vue'),
        meta: {
            breadcrumb: [
                ...DASHBOARD_BREADCRUMB,
                { text: 'Support' }
            ]
        }
    },
    {
        path: '/dashboard/domains/:id',
        name: 'dashboard-domain',
        component: () => import(/* webpackChunkName: "dashboard-domain" */ '../../views/dashboard/domain/Domain.vue'),
        meta: {
            breadcrumb: [
                ...DASHBOARD_BREADCRUMB,
                { text: 'Domain' }
            ]
        }
    },
    {
        path: '/dashboard/teamspeak-server/:id',
        name: 'dashboard-teamspeak-server',
        component: () => import(/* webpackChunkName: "dashboard-teamspeak-server" */ '../../views/dashboard/teamspeak/TeamspeakServer.vue'),
        meta: {
            breadcrumb: [
                ...DASHBOARD_BREADCRUMB,
                { text: 'Teamspeak Server' }
            ]
        }
    },
    {
        path: '/dashboard/rootserver/:id',
        name: 'dashboard-rootserver',
        component: () => import(/* webpackChunkName: "dashboard-rootserver" */ '../../views/dashboard/rootserver/RootServer.vue'),
        meta: {
            breadcrumb: [
                ...DASHBOARD_BREADCRUMB,
                { text: 'Root-Server' }
            ]
        }
    },
    {
        path: '/dashboard/minecraftserver/:id',
        name: 'dashboard-minecraftserver',
        component: () => import(/* webpackChunkName: "dashboard-minecraftserver" */ '../../views/dashboard/minecraftserver/MinecraftServer.vue'),
        meta: {
            breadcrumb: [
                ...DASHBOARD_BREADCRUMB,
                { text: 'Minecraft-Server' }
            ]
        }
    },
    {
        path: '/dashboard/webspace/:id',
        name: 'dashboard-webspace',
        component: () => import(/* webpackChunkName: "dashboard-webspace" */ '../../views/dashboard/webspace/Webspace.vue'),
        meta: {
            breadcrumb: [
                ...DASHBOARD_BREADCRUMB,
                { text: 'Webspace' }
            ]
        }
    },
    {
        path: '/dashboard/nextcloud/:id',
        name: 'dashboard-nextcloud',
        component: () => import(/* webpackChunkName: "dashboard-nextcloud" */ '../../views/dashboard/nextcloud/Nextcloud.vue'),
        meta: {
            breadcrumb: [
                ...DASHBOARD_BREADCRUMB,
                { text: 'Nextcloud' }
            ]
        }
    }
]