import Vue from 'vue'
import Vuex from 'vuex'
import xeonserv from '../apis/xeonserv'
import router from '../router'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    current_user: undefined,
    impersonatedUserId: undefined,
    testimonials: []
  },
  mutations: {
    setCurrentUser(state, user) {
      state.current_user = user
    },
    setImpersonatedUserId(state, userId) {
      state.impersonatedUserId = userId
    },
    setTestimonials(state, testimonials) {
      state.testimonials = testimonials
    }
  },
  actions: {
    loadCurrentUser(context) {
      if(localStorage.getItem('token'))
        xeonserv().get('/auth/me').then(res => context.commit('setCurrentUser', res.data.data))
      else
        context.commit('setCurrentUser', undefined)
    },
    impersonateUser(context, user) {
      context.commit('setImpersonatedUserId', user ? user.id : null)
      console.log(user)
      context.dispatch('loadCurrentUser')
      if(user) {
        router.push({ name: 'dashboard-account' })
      } else {
        router.go()
      }
    },
    loadTestimonials(context) {
      xeonserv().get('/testimonials', { params: { page: 1, page_size: 3, as_user: true } }).then(res => context.commit('setTestimonials', res.data.data))
    }
  },
  modules: {
  }
})
