import axios from 'axios'
import config from '../util/config'
import bus from '../bus'
import store from '../store'

function showMessages(res) {
    for(let m of res.infos)
        bus.$emit('notification:show', { type: 'success', message: m })
    for(let m of res.warnings)
        bus.$emit('notification:show', { type: 'warning', message: m })
    for(let m of res.errors)
        bus.$emit('notification:show', { type: 'error', message: m })
}

export default function() {
    const client = axios.create({
        baseURL: config.apiUrl,
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
            ...(store.state.impersonatedUserId ? {'Impersonate-User': store.state.impersonatedUserId} : {})
        }
    })
    client.interceptors.response.use(res => {
        showMessages(res.data)
        return res
    }, err => {
        showMessages(err.response.data)
        throw err
    })
    return client
}