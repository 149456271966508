import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: false,
        themes: {
          options: {
            customProperties: true
          },
          light: {
            primary: '#0087CD'
          },
          dark: {
            primary: '#0087CD'
          }
        },
    }
});
