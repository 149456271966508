<template>
    <v-dialog max-width="600px" v-model="open">
        <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" v-bind="attrs" v-on="on" text>Registrieren</v-btn>
        </template>
        <v-card>
            <v-card-title>
                <span class="headline">Registrieren</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="open = false">Abbrechen</v-btn>
                <v-btn @click="open = false" color="primary">Registrieren</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import xeonserv from '../../apis/xeonserv'

export default {
    data() {
        return {
            open: false
        }
    },
    created() {
        this.bus.$on('register:show', () => this.open = true)
    },
    methods: {
        register() {
            xeonserv;
        }
    }
}
</script>